<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Fiche produit #{{produit.id}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="success" size="sm" class="btn-icon mr-1" @click="save"><i class="icon-md fas fa-save"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-form-group label="Titre:">
            <b-form-input v-model="produit.titre" type="text" required placeholder="Entre le titre"></b-form-input>
          </b-form-group>
          <div class="form-group">
            <label>Type:</label>
            <select class="form-control" v-model="produit.type_id">
              <option v-for="(t,i) in parametrage.types" :value="t.id" :key="i">{{t.titre}}</option>
            </select>
          </div>
          <b-form-group label="Prix de vente:">
            <b-form-input v-model.number="produit.prix" type="number" required placeholder="Entre le prix"></b-form-input>
          </b-form-group>
          <b-form-group label="Prix d'achat:">
            <b-form-input v-model.number="produit.prix_achat" type="number" required placeholder="Entre le prix"></b-form-input>
          </b-form-group>
          <b-form-group label="Duree de la formation en semaine:">
            <b-form-input v-model.number="produit.duree" type="number" required placeholder="Entree une duree"></b-form-input>
          </b-form-group>
          <b-form-group label="Url de guide:">
            <b-form-input v-model="produit.url_gide" type="text" required placeholder="Entree une Url"></b-form-input>
          </b-form-group>
          <b-form-group label="Url de MonCompteFormation:">
            <b-form-input v-model="produit.url_gov" type="text" required placeholder="Entree une Url"></b-form-input>
          </b-form-group>
          <div class="form-group">
            <label>Statut:</label>
            <select class="form-control" v-model="produit.status">
              <option v-for="(s,i) in parametrage.status" :value="s.id" :key="i">{{s.titre}}</option>
            </select>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      produit:{
        id: null,
        titre: null,
        prix: 0,
        prix_achat:0,
        status: null,
        date_create: null,
        date_update: null,
        duree: null,
        type_id: null,
        url_gide: null,
        url_gov: null,
      },
      parametrage:{
        status:[],
      },
      types:[],
    };
  },
  components: {
  },
  computed: {
  },
  methods: {
    save() {
      this.produit.prix = parseInt(this.produit.prix);
      this.$store.back.ajax('/parametrage/update?module=Produit', this.produit, (res) => {
        if(res.status === true){
          this.produit = res.data;
          this.$bvToast.toast(`Vos modification sont sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    },
  },
  watch: {
  },
  beforeMount(){
    let params = {
      modules: ["ProduitType"]
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.status = res.data['status'];
        this.parametrage.types = res.data['ProduitType'];
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.$store.back.ajax(`/parametrage/${this.$route.params.id}?module=Produit`, null, (res) => {
      if(res.status === true){
        this.produit = res.data.model;
      }
    });
  }
};
</script>
